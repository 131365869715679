<template>
    <li class="list__item"
        :class="{'item_active': isActive}"
        v-show="isFilteredGeozone && (accessRight >= getAccessRights.read)"
        @click="setGeoitem"
        @mouseover="showMenu = true" @mouseleave="showMenu = false"
    >
        <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
        <h4 class="list__title">{{name}}</h4>

        <GeoitemsList_geoitem__status :geozone-id="geozoneId" />
        <GeoitemsList_geoitem_menu :geozone-id="geozoneId" v-if="showMenu" />
    </li>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import { getAccessRights } from '@/lib/access-rights'
    import GeoitemsList_geoitem_menu from "./GeoitemsList_geoitem_menu.vue";
    import GeoitemsList_geoitem__status from "./GeoitemsList_geoitem__status.vue";

    export default {
        name: 'GeoitemsList_geoitem',
        components: {
            GeoitemsList_geoitem_menu,
            GeoitemsList_geoitem__status,
        },
        props: [
            'groupId',
            'geozoneId'
        ],
        data() {
            return {
                getAccessRights,
                showMenu: false,
            }
        },
        computed: {
            ...mapGetters([
                "filteredGeozones",
                "getGeozoneActive",
                "geozonesByIds",

                "showSectionInfo",
                "showSectionWidget",
                "getSectionInfo",
                "getSectionWidget"
            ]),
            geoitem(){
                return this.geozonesByIds[this.geozoneId]
            },
            accessRight(){
                return  this.geoitem.access_right
            },
            name(){
                return (this.geoitem) ? this.geoitem.name : ''
            },
            isFilteredGeozone(){
                return this.filteredGeozones.includes(this.geozoneId)
            },
            isActive(){
                return this.getGeozoneActive === this.geozoneId
            },
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
                "setSectionWidget",
                "setGeoitemEdit",
                "setMapBoundsByGeozones",
                "setGeozoneActive",
            ]),
            ...mapMutations([
                "setAllGeoitemsShowOnMap"
            ]),
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        geozoneId: this.geozoneId,
                        time: Date.now()
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        geozoneId: this.geozoneId,
                        time: Date.now()
                    }
                })
            },
            setGeoitem(){
                this.setGeozoneActive(this.geozoneId)
                this.setAllGeoitemsShowOnMap([this.geozoneId])
                this.setMapBoundsByGeozones([this.geozoneId])
                if(this.showSectionInfo){
                    let sectionInfo = this.getSectionInfo
                    sectionInfo.props = {
                        geozoneId: this.geozoneId,
                        time: Date.now()
                    }
                    this.setSectionInfo(sectionInfo)
                }
                if(this.showSectionWidget){
                    let sectionWidget = this.getSectionWidget
                    sectionWidget.props = {
                        geozoneId: this.geozoneId,
                        time: Date.now()
                    }
                    this.setSectionWidget(sectionWidget)
                }
                if(this.getGeoitemEdit) {
                    let geozone = {...this.geozonesByIds[this.geozoneId]}
                    this.setGeoitemEdit({...geozone, geozoneId: this.geozoneId})
                }
            },
            onEdit(){
                if(process.env.VUE_APP_PACKAGE === "lite") {
                    this.displaySectionInfo('GeozoneEdit')
                }else{
                    this.displaySectionWidget('GeozoneEdit')
                }
            }
        },
        updated() {
            //console.log('GeoitemsList_geoitem updated')
        },
    }
</script>

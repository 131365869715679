<template>
    <ButtonIcon_dual_status
        :button-class="'mtb__-4'"
        :disabled="true"
        :icon="isOnMap ? 'common__show' : 'common__show_disabled'"
        :icons="['common__show', 'common__show_disabled']"
        :value="isOnMap"
        @click="setOnMap"
    />
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "GeoitemsList_geoitem_menu_onmap",
    props: [
        'geozoneId',
    ],
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            "geoitemsOnMap",
        ]),
        isOnMap() {
            return this.geoitemsOnMap.includes(this.geozoneId)
        },
    },
    methods: {
        ...mapActions([
            "setGeoitemShowOnMap",
        ]),
        setOnMap() {
            this.setGeoitemShowOnMap({
                geoitemId: this.geozoneId,
                showOnMap: !this.isOnMap
            });
            return false;
        },
    },
    updated: function () {
        // console.log(`Geoitem menu onmap ${this.geozoneId} updated`, this.isOnMap);
    },
}
</script>

<style scoped>

</style>
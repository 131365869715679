import { render, staticRenderFns } from "./GeoitemsList_navigation.lite.vue?vue&type=template&id=4fda18cf&scoped=true&"
import script from "./GeoitemsList_navigation.lite.vue?vue&type=script&lang=js&"
export * from "./GeoitemsList_navigation.lite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fda18cf",
  null
  
)

export default component.exports
<template>
    <section class="section__body">

        <!--- Navigation --->
        <GeoitemsList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
<!--            <ul class="list mb__12">-->
            <DynamicScroller
                    :items="geoitemsGroupsWithSize"
                    :min-item-size="44"
                    :buffer="100"
                    page-mode
            >
                <template #before>
                    <!-- start -->
                </template>

                <template v-slot="{ item, active }">
                    <DynamicScrollerItem
                            :item="item"
                            :active="active"
                            :size-dependencies="[filteredGeozones,item.geozones,]"
                            tag="ul"
                            class="list mb__12 list_view_insular"
                    >
                    <GeoitemsList_geoitem
                            v-for="geozoneId in item.geozones"
                            :key="'GeoitemsList_geoitem-'+item.id+'-geozone-'+geozoneId"
                            :group-id="item.id"
                            :geozone-id="geozoneId"
                    />
                    </DynamicScrollerItem>
                </template>

                <template #after>
                    <!--  end -->
                </template>
            </DynamicScroller>
<!--            </ul>-->
        </section>

    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import GeoitemsList_navigation from "./GeoitemsList_navigation.lite.vue";
    import GeoitemsList_geoitem from "./GeoitemsList_geoitem.vue";

    export default {
        name: "GeoitemsList",
        components: {
            GeoitemsList_geoitem,
            GeoitemsList_navigation,
        },
        data() {
            return {
                pageActive: false,
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "geozones",
                "geozonesCount",
                "filteredGeozones",
            ]),
            geoitemsGroupsWithSize(){
                let size = 40*this.filteredGeozones.length
                return [{
                    id: -1,
                    name: 'Geoitems ('+this.geozonesCount+')',
                    geozones: this.geozones.map(gz => gz.id),
                    size: 44+12+size
                }]
            },
        },
        methods: {
            ...mapActions([
                // "setMapBoundsByGeozones",
                "fetchGeozones",
                "fetchGeozonesLite",
                "fetchGeozonesGroups",
                "fetchNotificationsWith",
            ]),
        },
        created() {
            if(!this.geozones.length) {
                this.fetchGeozonesLite()
            }
        },
        updated() {
            //console.log('GeoitemsList updated')
        },
        activated() {
            this.pageActive = true
            this.fetchGeozonesLite()
            this.fetchNotificationsWith()
            //else{this.setMapBoundsByGeozones(this.filteredGeozones())}
            this.interval = setInterval(() => {
                this.fetchGeozones()
            }, 30*1000)
        },
        deactivated() {
            this.pageActive = false
            if(this.interval) {
                clearInterval(this.interval)
                this.interval = null
            }
        },
        mounted(){
        },
        beforeDestroy() {
        },

    }
</script>


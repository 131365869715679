<template>
    <div class="list__status">
        <span class="icon" v-show="!isOnMap"> <span class="icon__symbol"><SvgIcon class="icon" name="common__status_not_show" /></span> </span>
    </div>
</template>

<script>
    import { mapGetters, } from "vuex";

    export default {
        name: "GeoitemsList_geoitem__status",
        props: [
            'geozoneId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "geoitemsOnMap",
            ]),
            isOnMap() {
                return this.geoitemsOnMap.includes(this.geozoneId)
            },
        },
        methods: {
        },
        updated: function() {
            // console.log(`Geoitem status ${this.geozoneId} updated`, this.isOnMap);
        },
    }
</script>

<style scoped>

</style>
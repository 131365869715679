<template>
    <!--- Navigation --->
    <nav class="section__navigation navigation pfull__12">
        <div class="horizontal-line">
            <div class="filter-search mr__8 "
                 :class="{'filter-search_active': FilterSearchValue>'','filter-search_focus': FilterSearchFocusin}"
            >
                <button class="filter-search__filter button button_view_icon button_theme_primary button_dropdown-menu"
                        @click="switchShowFilterDropdownMenu"
                >
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__slider" /></span> </span>
                </button>
                <ul class="filter-search__choice">
                    <!-- <li class="filter-search__choice-item">Name</li>-->
                    <!-- <li class="filter-search__choice-item">Lost connection</li>-->
                    <!-- <li class="filter-search__choice-item">Control of the entrance to geosites</li>-->
                    <!-- <li class="filter-search__choice-item">Sensors control</li>-->
                </ul>
                <input class="filter-search__input" type="text" :placeholder="$t('text.Search')" v-model="FilterSearchValue" @focus="FilterSearchFocusin=true" @blur="FilterSearchFocusin=false">
                <button class="filter-search__close button button_view_icon button_theme_primary" @click="FilterSearchValue=''">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close_sm" /></span> </span>
                </button>
                <div class="filter-search__dropdown-menu dropdown-menu"
                     :class="{'dropdown-menu_open': showFilterDropdownMenu}"
                >
                    <div class="dropdown-menu__wrap">
                        <ul class="dropdown-menu__list list">
                            <li class="dropdown-menu__item item item_type_separator"></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="navigation__button-group">
                <button class="button button_view_icon button_theme_base mtb__-4" data-type="open-drop-menu">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__more_vertical" /></span> </span>
                </button>
                <div class="navigation__dropdown-menu dropdown-menu position_right-bottom" data-type="" >
                    <div class="dropdown-menu__wrap">
                        <ul class="dropdown-menu__list">
                            <li class="dropdown-menu__item item" v-show="!isAllGeoitemsShowOnMap" @click="_setGeoitemsShowOnMap({showOnMap: true, geoitemIds: filteredGeozones})">
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__show" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Show all geoitems on map') }}</span>
                            </li>
                            <li class="dropdown-menu__item item" v-show="isAllGeoitemsShowOnMap" @click="_setGeoitemsShowOnMap({showOnMap: false, geoitemIds: filteredGeozones})">
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__show_disabled" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Hide all geoitems on map') }}</span>
                            </li>

                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item" @click="displaySectionInfo('GeozoneEdit')">
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Add a geoitem') }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";

    export default {
        name: "GeoitemsList_navigation",
        props: [],
        components: {},
        data() {
            return {
                FilterSearchFocusin: false,
            }
        },
        computed: {
            ...mapGetters([
                "geozonesFilter",
                "filteredGeozones",
                "getFilterSearchLists",
                "showFilterDropdownMenu",
                "geoitemsOnMap"
            ]),
            FilterSearchValue: {
                get(){
                    // console.log('FilterSearchValue.get', this.geozonesFilter.name)
                    return this.geozonesFilter.name
                },
                set(value){
                    let geozonesFilter = this.geozonesFilter
                    geozonesFilter.name = value
                    this.setGeozonesFilter(geozonesFilter)
                },
            },
            isAllGeoitemsShowOnMap() {
                // console.log('isAllUnitsShowOnMap', this.unitsCount, this.unitsOnMap.length)
                //return this.unitsOnMap.length == this.unitsCount
                let ids = this.filteredGeozones.filter(id => {
                    return !this.geoitemsOnMap.includes(id)
                })
                return ids.length === 0
            },
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
                "setSectionWidget",
                "setGeoitemsShowOnMap",
                "setMapBoundsByGeozones"
            ]),
            ...mapMutations([
                "setGeozonesFilter",
                "switchShowFilterDropdownMenu",
                "setShowFilterDropdownMenu",
            ]),
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        geoitemId: null,
                        time: Date.now()
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        geoitemId: null,
                        time: Date.now()
                    }
                })
            },
            setGeozonesFilterParam(param, value){
                let geozonesFilter = this.geozonesFilter
                geozonesFilter[param] = value
                this.setGeozonesFilter(geozonesFilter)
            },
            resetGeozonesFilter(){
                let geozonesFilter = this.geozonesFilter
                geozonesFilter.type = false
                // let geozonesFilter = {
                //     type: false,
                //     name: '',
                // }
                this.setGeozonesFilter(geozonesFilter)
            },
            _setGeoitemsShowOnMap(args){
                //console.log(args)
                this.setGeoitemsShowOnMap(args)
                if(args.showOnMap) {
                    this.setMapBoundsByGeozones(args.geoitemIds)
                }
            }
        },
        updated() {
            //console.log(`GeoitemsList_navigation updated`)
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>
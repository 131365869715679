<template>
    <div class="list__button-group">
        <template v-for="(item, i) in menus">
            <button class="button button_view_icon button_theme_base mtb__-4"
                    v-if="accessRight >= item.access_right"
                    :key="'GeoitemsList_geoitem_menu__item_'+i"
                    :class="item.class"
                    @click="bindToMe(item.onClick)()"
            >
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="item.icon"/></span> </span>
            </button>
        </template>
        <GeoitemsList_geoitem_menu_onmap :geozone-id="geozoneId" />
    </div>
</template>
<script>
    import { mapActions, mapGetters } from "vuex";
    import { getAccessRights } from '@/lib/access-rights'
    import GeoitemsList_geoitem_menu_onmap from "./GeoitemsList_geoitem_menu_onmap.vue";

    export default {
        name: 'GeoitemsList_geoitem_menu',
        props: [
            'geozoneId',
        ],
        components: {GeoitemsList_geoitem_menu_onmap},
        data() {
            return {
                menus_full: [
                    {
                        "class": "",
                        "icon": "common__edit",
                        "onClick": function () { this.displaySectionInfo('GeozoneEdit') },
                        "access_right": getAccessRights.read,
                    },
                    {
                        "class": "is_danger",
                        "icon": "common__delete",
                        "onClick": function () { this.popupDeleteGeozone() },
                        "access_right": getAccessRights.full
                    }
                ],
                menus_lite: [
                    {
                        "class": "",
                        "icon": "common__edit_lg",
                        "onClick": function () { this.displaySectionInfo('GeozoneEdit') },
                        "access_right": getAccessRights.read,
                    },
                    {
                        "class": "is_danger",
                        "icon": "common__delete_lg",
                        "onClick": function () { this.popupDeleteGeozone() },
                        "access_right": getAccessRights.full,
                    }
                ],
            }
        },
        computed: {
            ...mapGetters([
                "getGeoitemEdit",
                "geozonesByIds"
            ]),
            menus(){
                return (process.env.VUE_APP_PACKAGE === "lite")? this.menus_lite : this.menus_full
            },
            geoitem(){
                return this.geozonesByIds[this.geozoneId]
            },
            accessRight(){
                return this.geoitem.access_right
            },
            geoitemName(){
                return (this.geoitem) ? this.geoitem.name : ''
            },
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
                "setSectionWidget",
                "setGeoitemEdit",
                "deleteGeozone",
                "setPopup",
            ]),
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        geozoneId: this.geozoneId,
                        time: Date.now()
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        geozoneId: this.geozoneId,
                        time: Date.now()
                    }
                })
            },
            popupDeleteGeozone(){
                this.setPopup({
                    title: `Delete geozone ${this.geoitemName}`,
                    methods: {
                        ok: () => this._deleteGeozone(),
                        //cancel: () => {},
                    }
                })
            },
            _deleteGeozone(){
                this.deleteGeozone(this.geozoneId)
                    .then((/*res*/) => {
                        //console.log(res)
                        this.$toast.success(this.$t("deleted!"))
                        //console.log(this.getGeoitemEdit, this.geozoneId)
                        if(this.getGeoitemEdit && this.getGeoitemEdit.geozoneId == this.geozoneId){
                            this.setGeoitemEdit(null)
                            this.setSectionInfo(null)
                            //this.$emit('close-dialog')
                        }
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response)
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                    })
            },
            bindToMe(handler) {
                return handler.bind(this);
            },
            //https://stackoverflow.com/questions/52811581/how-to-pass-a-dynamic-function-name-to-the-click-event-in-vue-js
            //[item.click](item.click_params)
            // @click="handle_function_call(item.click, item.click_params)"
            handle_function_call(function_name, function_params) {
                this[function_name](function_params)
            },
        },
        updated: function() {
            // console.log(`GeoitemsList_geoitem_menu ${this.geozoneId} updated`);
        },
    }
</script>